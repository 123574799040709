custom-report {
  @import (less) '~codemirror/lib/codemirror.css';
  @import (less) '~codemirror/addon/hint/show-hint.css';

  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .cr {
    &-container {
      height: calc(100% - 125px);
    }

    &-nav {
      align-items: center;
      display: flex;
      padding: 10px 20px;

      &-item {
        align-items: center;
        display: flex;
        flex: 1 1 100%;

        > *:not(:last-child) {
          margin-right: 15px;
        }

        &:last-child {
          justify-content: flex-end;
        }

        input {
          margin-bottom: 0;
        }
      }
    }

    &-top {
      display: flex;
      height: 400px;

      &-editor {

        .filters {
          margin-top: 0;
        }

        flex-basis: 56%;
        max-width: 56%;
        padding-right: 3rem;

        sql-editor {
          height: calc(100% - 7.8rem);
        }
      }

      &-sidenav {
        box-sizing: border-box;
        flex-basis: 44%;
        height: 100%;
        max-width: 44%;
        padding-left: 3rem;

        .tab-content {
          max-height: calc(100% - 6.2rem);
          overflow: auto;
        }
      }
    }
  }
}