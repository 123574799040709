report-list {
  .sub-header__column-left {
    width: auto;
  }

  .sub-header__column-right {
    display: none;
  }

  .report-list-row {
    vertical-align: middle;
    td:first-child {
      width: 25px;
    }
    td:last-child {
      width: 80px;
    }
  }

  .input-prepend {
    margin-bottom: 0;
  }

  .report-list-filter-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;

    > * {
      margin: 5px;
    }

    .input-prepend {

      label {
        margin-bottom: 0;
      }
    }
  }


  .actions {
    display: flex;
    align-items: stretch;
    margin: 0 -5px;

    > * {
      margin: 5px;
      display: flex;
      align-items: center;
    }
  }

  a[disabled] {
    pointer-events: none;
  }
}