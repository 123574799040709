custom-fee label.fee-as-board {
  display: flex;
  align-items: center;
  padding-left: 15px;
  margin-top: 5px;
}
custom-fee label.fee-as-board input {
  vertical-align: bottom;
  position: relative;
  margin: 0;
}
custom-fee td:nth-child(2) {
  width: 20%;
}
custom-fee .calculate-on-field {
  align-items: center;
  display: flex;
  margin: 0 -5px;
}
custom-fee .calculate-on-field > * {
  margin: 0 5px;
}
custom-fee select {
  margin: 0;
}
