customer-profile-body {
  /*#cpr_tab_income */
}
customer-profile-body .profile-body .tab-menu ul.nav {
  margin-bottom: 0;
}
customer-profile-body .profile-body .tab-content .form-header {
  color: darkgray;
  text-align: right;
  border-bottom: solid 1px lightgray;
  margin: 20px 20px 0 20px;
  padding-right: 10px;
}
customer-profile-body .profile-body hr {
  margin: 0;
  border: none;
  border-top: solid 1px lightgray;
}
customer-profile-body .profile-body form {
  margin-bottom: 0;
  padding-top: 0;
}
customer-profile-body #cpr_tab_documents .doc-form {
  min-height: 200px;
}
customer-profile-body #cpr_tab_income hr {
  margin-top: 2px;
  margin-bottom: 30px;
}
customer-profile-body #cpr_inc_other_description {
  width: 607px;
}
customer-profile-body #cpr_tab_income .template {
  display: none;
}
customer-profile-body #cpr_tab_related_people {
  padding-top: 0;
}
customer-profile-body .profile-related-people {
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
}
customer-profile-body .profile-related-people input,
customer-profile-body .profile-related-people select {
  margin-bottom: 0;
}
customer-profile-body .profile-related-people select {
  width: auto;
}
customer-profile-body .profile-related-people input[disabled] {
  border: none;
  outline: 0;
  background-color: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
}
customer-profile-body .profile-related-people .vertical-center {
  padding: 4px;
}
customer-profile-body div[ng-transclude]:empty {
  display: none;
}
