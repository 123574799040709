custom-report {
  /* BASICS */
  /* PADDING */
  /* GUTTER */
  /* CURSOR */
  /* Shown when moving in bi-directional text */
  /* Can style cursor different in overwrite (non-insert) mode */
  /* DEFAULT THEME */
  /* Default styles for common addons */
  /* STOP */
  /* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */
  /* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
  /* Force content-box sizing for the elements where we expect it */
  /* Used to force a border model for a node */
  /* See issue #2901 */
  /* Help users use markselection to safely style text background */
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
custom-report .CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
  color: black;
  direction: ltr;
}
custom-report .CodeMirror-lines {
  padding: 4px 0;
  /* Vertical padding around content */
}
custom-report .CodeMirror pre {
  padding: 0 4px;
  /* Horizontal padding of content */
}
custom-report .CodeMirror-scrollbar-filler,
custom-report .CodeMirror-gutter-filler {
  background-color: white;
  /* The little square between H and V scrollbars */
}
custom-report .CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap;
}
custom-report .CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
}
custom-report .CodeMirror-guttermarker {
  color: black;
}
custom-report .CodeMirror-guttermarker-subtle {
  color: #999;
}
custom-report .CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0;
}
custom-report .CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}
custom-report .cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7;
}
custom-report .cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}
custom-report .cm-fat-cursor-mark {
  background-color: rgba(20, 255, 20, 0.5);
  -webkit-animation: blink 1.06s steps(1) infinite;
  -moz-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
}
custom-report .cm-animate-fat-cursor {
  width: auto;
  border: 0;
  -webkit-animation: blink 1.06s steps(1) infinite;
  -moz-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
  background-color: #7e7;
}
@-moz-keyframes blink {
  50% {
    background-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    background-color: transparent;
  }
}
@keyframes blink {
  50% {
    background-color: transparent;
  }
}
custom-report .cm-tab {
  display: inline-block;
  text-decoration: inherit;
}
custom-report .CodeMirror-rulers {
  position: absolute;
  left: 0;
  right: 0;
  top: -50px;
  bottom: -20px;
  overflow: hidden;
}
custom-report .CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0;
  bottom: 0;
  position: absolute;
}
custom-report .cm-s-default .cm-header {
  color: blue;
}
custom-report .cm-s-default .cm-quote {
  color: #090;
}
custom-report .cm-negative {
  color: #d44;
}
custom-report .cm-positive {
  color: #292;
}
custom-report .cm-header,
custom-report .cm-strong {
  font-weight: bold;
}
custom-report .cm-em {
  font-style: italic;
}
custom-report .cm-link {
  text-decoration: underline;
}
custom-report .cm-strikethrough {
  text-decoration: line-through;
}
custom-report .cm-s-default .cm-keyword {
  color: #708;
}
custom-report .cm-s-default .cm-atom {
  color: #219;
}
custom-report .cm-s-default .cm-number {
  color: #164;
}
custom-report .cm-s-default .cm-def {
  color: #00f;
}
custom-report .cm-s-default .cm-variable-2 {
  color: #05a;
}
custom-report .cm-s-default .cm-variable-3,
custom-report .cm-s-default .cm-type {
  color: #085;
}
custom-report .cm-s-default .cm-comment {
  color: #a50;
}
custom-report .cm-s-default .cm-string {
  color: #a11;
}
custom-report .cm-s-default .cm-string-2 {
  color: #f50;
}
custom-report .cm-s-default .cm-meta {
  color: #555;
}
custom-report .cm-s-default .cm-qualifier {
  color: #555;
}
custom-report .cm-s-default .cm-builtin {
  color: #30a;
}
custom-report .cm-s-default .cm-bracket {
  color: #997;
}
custom-report .cm-s-default .cm-tag {
  color: #170;
}
custom-report .cm-s-default .cm-attribute {
  color: #00c;
}
custom-report .cm-s-default .cm-hr {
  color: #999;
}
custom-report .cm-s-default .cm-link {
  color: #00c;
}
custom-report .cm-s-default .cm-error {
  color: #f00;
}
custom-report .cm-invalidchar {
  color: #f00;
}
custom-report .CodeMirror-composing {
  border-bottom: 2px solid;
}
custom-report div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}
custom-report div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}
custom-report .CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3);
}
custom-report .CodeMirror-activeline-background {
  background: #e8f2ff;
}
custom-report .CodeMirror {
  position: relative;
  overflow: hidden;
  background: white;
}
custom-report .CodeMirror-scroll {
  overflow: scroll !important;
  /* Things will break if this is overridden */
  /* 30px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -30px;
  margin-right: -30px;
  padding-bottom: 30px;
  height: 100%;
  outline: none;
  /* Prevent dragging from highlighting the element */
  position: relative;
}
custom-report .CodeMirror-sizer {
  position: relative;
  border-right: 30px solid transparent;
}
custom-report .CodeMirror-vscrollbar,
custom-report .CodeMirror-hscrollbar,
custom-report .CodeMirror-scrollbar-filler,
custom-report .CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
}
custom-report .CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
custom-report .CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}
custom-report .CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0;
}
custom-report .CodeMirror-gutter-filler {
  left: 0;
  bottom: 0;
}
custom-report .CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  z-index: 3;
}
custom-report .CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -30px;
}
custom-report .CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important;
}
custom-report .CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4;
}
custom-report .CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}
custom-report .CodeMirror-gutter-wrapper ::selection {
  background-color: transparent;
}
custom-report .CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: transparent;
}
custom-report .CodeMirror-lines {
  cursor: text;
  min-height: 1px;
  /* prevents collapsing before first draw */
}
custom-report .CodeMirror pre {
  /* Reset some styles that the rest of the page might have set */
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
}
custom-report .CodeMirror-wrap pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}
custom-report .CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}
custom-report .CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px;
  /* Force widget margins to stay inside of the container */
}
custom-report .CodeMirror-rtl pre {
  direction: rtl;
}
custom-report .CodeMirror-code {
  outline: none;
}
custom-report .CodeMirror-scroll,
custom-report .CodeMirror-sizer,
custom-report .CodeMirror-gutter,
custom-report .CodeMirror-gutters,
custom-report .CodeMirror-linenumber {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
custom-report .CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
custom-report .CodeMirror-cursor {
  position: absolute;
  pointer-events: none;
}
custom-report .CodeMirror-measure pre {
  position: static;
}
custom-report div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}
custom-report div.CodeMirror-dragcursors {
  visibility: visible;
}
custom-report .CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}
custom-report .CodeMirror-selected {
  background: #d9d9d9;
}
custom-report .CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}
custom-report .CodeMirror-crosshair {
  cursor: crosshair;
}
custom-report .CodeMirror-line::selection,
custom-report .CodeMirror-line > span::selection,
custom-report .CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}
custom-report .CodeMirror-line::-moz-selection,
custom-report .CodeMirror-line > span::-moz-selection,
custom-report .CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}
custom-report .cm-searching {
  background-color: #ffa;
  background-color: rgba(255, 255, 0, 0.4);
}
custom-report .cm-force-border {
  padding-right: 0.1px;
}
@media print {
  custom-report {
    /* Hide the cursor when printing */
  }
  custom-report .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}
custom-report .cm-tab-wrap-hack:after {
  content: '';
}
custom-report span.CodeMirror-selectedtext {
  background: none;
}
custom-report .CodeMirror-hints {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 2px;
  -webkit-box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 1px solid silver;
  background: white;
  font-size: 90%;
  font-family: monospace;
  max-height: 20em;
  overflow-y: auto;
}
custom-report .CodeMirror-hint {
  margin: 0;
  padding: 0 4px;
  border-radius: 2px;
  white-space: pre;
  color: black;
  cursor: pointer;
}
custom-report li.CodeMirror-hint-active {
  background: #08f;
  color: white;
}
custom-report .cr-container {
  height: calc(100% - 125px);
}
custom-report .cr-nav {
  align-items: center;
  display: flex;
  padding: 10px 20px;
}
custom-report .cr-nav-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
}
custom-report .cr-nav-item > *:not(:last-child) {
  margin-right: 15px;
}
custom-report .cr-nav-item:last-child {
  justify-content: flex-end;
}
custom-report .cr-nav-item input {
  margin-bottom: 0;
}
custom-report .cr-top {
  display: flex;
  height: 400px;
}
custom-report .cr-top-editor {
  flex-basis: 56%;
  max-width: 56%;
  padding-right: 3rem;
}
custom-report .cr-top-editor .filters {
  margin-top: 0;
}
custom-report .cr-top-editor sql-editor {
  height: calc(100% - 7.8rem);
}
custom-report .cr-top-sidenav {
  box-sizing: border-box;
  flex-basis: 44%;
  height: 100%;
  max-width: 44%;
  padding-left: 3rem;
}
custom-report .cr-top-sidenav .tab-content {
  max-height: calc(100% - 6.2rem);
  overflow: auto;
}
