custom-fee {

  label.fee-as-board {
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin-top: 5px;

    input {
      vertical-align: bottom;
      position: relative;
      margin: 0;
    }
  }

  td:nth-child(2) {
    width: 20%;
  }

  .calculate-on-field {
    align-items: center;
    display: flex;
    margin: 0 -5px;

    & > * {
      margin: 0 5px;
    }
  }

  select {
    margin: 0;
  }
}
